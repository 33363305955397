import * as React from "react"

export default function IconFAQ() {
  return (
    <svg 
      width="13" 
      height="12" 
      viewBox="0 0 13 12" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M6.5 0.5L12.9952 11.75L0.00480938 11.75L6.5 0.5Z" 
        fill="#E7513D"
      />
    </svg>
  )
}