import React, { useState } from 'react'
import IconFAQ from "../components/icons/IconFAQ"
import showdown from "showdown";
import ReactMarkdown from 'react-markdown';

export default function Accordion({ question, answer }) {
  const [showAnswer, setShowAnswer] = useState(false);
  
  // = = = = = = = = 
  // Utils

  // Convert Markdown to HTML
  const converter = new showdown.Converter();
  //const htmlAnswer = converter.makeHtml(answer);

  // = = = = = = = = 
  // Event Handlers

  const handleClick = () => {
    setShowAnswer(!showAnswer);
  }

  return (
  <div class="accordion" id="accordion-faqs">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button onClick={ handleClick } class={`accordion-button ${showAnswer ? '' : 'collapsed'}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded={ showAnswer } aria-controls="collapseOne">
          
          { question }

          <span className="accordion-icon">
            <IconFAQ />
          </span>
        </button>
      </h2>
      <div id="collapseOne" class={`accordion-collapse collapse ${ showAnswer ? 'show' : '' }`} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <ReactMarkdown className="accordion-body">{ answer }</ReactMarkdown>
      </div>
    </div>
  </div>
  )
}


